
import LogHistory from "@/views/shared/logHistory.vue";
import fileuploadList from "@/components/fileuploadList";
import roles from "@/types/roles.ts";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
} from "vue";
import { copyFrom } from "@/network/common";
import {
  useSetupProjectAction,
  useQueryFileSignManageActionHistory,
} from "@/network/setupProject";
import {
  dicLabel,
  scheduleTypeDic,
  setupProjectStatusText,
} from "@/network/lookUp.ts";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: {
    fileuploadList,
    LogHistory,
  },
  props: [
    "id",
    "yearBatch",
    "signType",
    "applyUnitId",
    "applyUnitName",
    "redirect",
    "setupGroup"
  ],
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const router = useRouter();
    const route = useRoute();
    const {
      yearBatch = ref(null),
      signType = ref(null),
      applyUnitId = ref(null),
      applyUnitName = ref(null),
      setupGroup = ref(null),
      redirect = ref(null),
    } = toRefs(props);

    const [
      isLoading,
      ,
      ,
      findFileSign,
      saveFileSign,
      queryFileSign,
      findFileSignProjects,
      ,
      ,
      cancelProjectReview,
    ] = useSetupProjectAction();

    const isFileUploading = ref(false);
    const refTable = ref(null);
    const projects = ref([]);
    const selected = ref([]);
    const _backPath = ref(
      redirect.value || route.query.redirect || "/ReportAndReview/ProvSetup"
    );
    const empty = {
      id: 0,
      yearBatch: null,
      signType: null,
      status: 1,
      applyUnitId: null,
      attachList: [],
      extData: {}
    };

    const form = reactive(copyFrom(empty));
    const [logs, queryHistory] = useQueryFileSignManageActionHistory();
    const fileSetting = reactive({
      limit: 1,
      accpet: ".pdf",
      urlKey: "filePath",
      nameKey: "fileName",
      isFileUploading,
      typeGroup: ref({
        list: form.attachList,
        type: "申请文件",
      }),
    });

    const refForm = ref(null);

    onMounted(() => {
      if (props.id) {
        queryHistory(props.id);
        findFileSign(props.id).then((p) => {
          Object.assign(form, p);

          if (form.id == 0) {
            location.href = "/";
            return;
          }
          if (
            (form.applyUnitId != user.id || form.status != 1) &&
            !user.inRoles(roles.admin)
          ) {
            location.href = "/";
            return;
          }
          fileSetting.typeGroup.list = form.attachList;
          doFindFileSignProjects();
        });
      } else {
        if (!yearBatch.value) {
          const createPropsStr = sessionStorage.getItem(
            "setupProjectFileSignMange_CreateProps"
          );
          if (createPropsStr == "" || createPropsStr == null) {
            location.href = "/";
            return;
          } else {
            const createProps = JSON.parse(createPropsStr);
            yearBatch.value = createProps.yearBatch;
            signType.value = createProps.signType;
            applyUnitId.value = createProps.applyUnitId;
            applyUnitName.value = createProps.applyUnitName;
            _backPath.value = createProps.redirect;
            setupGroup.value = createProps.setupGroup;
          }
        }
        form.yearBatch = yearBatch.value;
        form.signType = signType.value;
        form.applyUnitId = applyUnitId.value;
        form.applyUnitName = applyUnitName.value;
        fileSetting.typeGroup.list = form.attachList;
        form.extData.setupGroup = setupGroup.value;
        doFindFileSignProjects();
        // findFileSignProjects(0).then((p) => {
        //   projects.value = p;
        // });
      }
    });

    const doFindFileSignProjects = () => {
      findFileSignProjects(form.id, { setupGroup: setupGroup.value }).then((p) => {
        projects.value = p;
        p.forEach((row) => {
          if (row.signId == form.id) {
            refTable.value.toggleRowSelection(row, true);
          }
        });
      });
    };

    const doBack = (item) => {
      cancelProjectReview(item.id).then(() => {
        doFindFileSignProjects();
      });
    };
    const doSave = (isSubmit = false) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            if (isSubmit) form.status = 2;
            saveFileSign(
              props.id,
              Object.assign({ projectIds: selected.value }, form)
            ).then((p) => {
              resolve("success");
              //emit("change", form);
              if (isSubmit) {
                router.push(_backPath.value);
              } else {
                if (form.id == 0) {
                  router.replace({ params: { id: p.id || 0 } });
                }
                Object.assign(form, p);
                fileSetting.typeGroup.list = form.attachList;
                queryHistory(p.id);
              }
            });
          } else {
            reject("error");
          }
        });
      });
    };
    const handleSelectionChange = (val) => {
      selected.value = val.map((d) => d.id);

      // projects.value.filter(d => !val.map(p => p.id).some(f => f == d.id)).forEach(p => {
      //   p.signId = null;
      // });

      //console.log(projects.value);
      //console.log(val.map(p => p.id));
    };

    return {
      logs,
      _backPath,
      refTable,
      handleSelectionChange,
      dicLabel,
      scheduleTypeDic,
      setupProjectStatusText,
      isFileUploading,
      fileSetting,
      projects,
      user,
      refForm,
      isLoading,
      form,
      doSave,
      doBack,
      rules: {
        // attachList: [
        //   {
        //     required: true,
        //     message: "请上传申报文件！",
        //     validator: (rule, value, callback) => {
        //       if (!value || value.length == 0) {
        //         return callback(Error(rule.message));
        //       }
        //       callback();
        //     },
        //   },
        // ],
        projects: [
          {
            required: true,
            message: "请选择申报项目",
            validator: (rule, value, callback) => {
              if (selected.value.length == 0) {
                return callback(Error(rule.message));
              }
              callback();
            },
          },
        ],
      },
    };
  },
});
