
import { defineComponent, ref, watch, onMounted, computed, inject } from "vue";
import roles from "@/types/roles.ts";
export default defineComponent({
  props: ["data"],
  setup(props) {
    const user:any = inject("user");
    const hiddenDataOp = ref(true);
    const listData = computed(() =>
      props.data.filter((p) => !hiddenDataOp.value || p.toStatus != -1)
    );

    return {
      user,
      roles,
      hiddenDataOp,
      listData,
    };
  },
});
