const title = require("../package.json").title;
export default {
  title: title,
  copyright: "Copyright @ 2021-2025 北京创智通科技发展有限公司",
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: "production",
  imgDefault: "/staticRescource/images/artnono.png",
  uploadAction: process.env.VUE_APP_BASE_API + "/upload",
  multipleUploadAction: process.env.VUE_APP_BASE_API + "/upload/multiple",
  browser: {
    directOpen: {
      mime: ["image/*", "video/*", "application/pdf", "audio/*"],
      route: "/resource/preview",
    },
  },
  attachment: {
    maxFileSize: 5,
    limit: 5,
    accept: [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.ms-excel",
      "image/gif",
      "image/jpeg",
      "application/pdf",
      "image/png",
      "video/mp4",
      "video/mov",
    ],
  },
  imageGallery: {
    maxFileSize: 5,
    minFileSize: 0.1,
    accept: ["image/png", "image/jpeg"],
    limit: 5,
  },
  cropperImage: {
    imgDefault: "/staticRescource/images/artnono.png",
    maxFileSize: 2,
    minFileSize: 0,
    width: 200,
    height: 200,
    accept: ["image/png", "image/jpeg"],
  },
  video: {
    imgVideoDefault: "/staticRescource/images/artnono-video.png",
    maxDuration: 30,
    minDuration: 15,
    maxFileSize: 30,
    minFileSize: 0,
    width: 320,
    height: 180,
    accept: ["video/mp4", "video/mov"],
  },
};
