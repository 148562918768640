<template>
  <div>
    <el-checkbox v-model="hiddenDataOp" v-if="user.inRoles(roles.prov)"
      >隐藏数据相关操作</el-checkbox
    >
    <el-table :data="listData">
      <el-table-column label="操作" prop="action_Name"></el-table-column>
      <el-table-column label="操作人" prop="create_UserName"></el-table-column>
      <el-table-column label="操作时间" prop="create_Date"></el-table-column>
      <el-table-column label="备注" prop="memo"></el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, computed, inject } from "vue";
import roles from "@/types/roles.ts";
export default defineComponent({
  props: ["data"],
  setup(props) {
    const user:any = inject("user");
    const hiddenDataOp = ref(true);
    const listData = computed(() =>
      props.data.filter((p) => !hiddenDataOp.value || p.toStatus != -1)
    );

    return {
      user,
      roles,
      hiddenDataOp,
      listData,
    };
  },
});
</script>

<style scoped></style>
