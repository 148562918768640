<template>
  <div class="borderForm" v-loading="isLoading">
    <el-tabs type="border-card">
      <el-tab-pane label="详细信息">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-space>
            <el-form-item label="年份批次">
              {{ form.yearBatch }}
            </el-form-item>
            <el-form-item label="汇总类型">
              {{ form.signType }}
            </el-form-item>
            <el-form-item label="编制单位">
              {{ form.applyUnitName }}
            </el-form-item>
          </el-space>
          <el-form-item label="申请文件" prop="attachList">
            <fileuploadList :setting="fileSetting" @upload="() => refForm.clearValidate('attachList')">
            </fileuploadList>
          </el-form-item>
          <el-form-item label="项目列表" prop="projects">
            <el-table :data="projects" v-loading="isLoading" @selection-change="handleSelectionChange" ref="refTable">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="编号" prop="code"></el-table-column>
              <el-table-column label="地区" prop="areaName"></el-table-column>
              <el-table-column label="名称" prop="project_Name" width="400">
                <template #default="{ row: item }">
                  {{ item.project_Name }}
                </template>
              </el-table-column>
              <el-table-column label="工程类型" prop="projectType">
                <template #default="{ row: item }">
                  {{
    item.isSafeProject
      ? dicLabel(item.safeProjectType, scheduleTypeDic)
      : dicLabel(item.projectType, scheduleTypeDic)
  }}
                </template>
              </el-table-column>
              <el-table-column label="文保单位" prop="heritageUnitName">
                <template #default="{ row: item }">
                  <a href="javascript:void()">{{ item.heritageUnitName }}</a>
                </template>
              </el-table-column>
              <el-table-column label="文保单位类型" prop="heritageUnitClassName">
              </el-table-column>
              <el-table-column label="是否革命文物" prop="heritageUnit_Name">
                <template #default="{ row: item }">
                  {{ item.isRevolution ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template #default="{ row: item }">{{
    setupProjectStatusText[item.status]
  }}</template>
              </el-table-column>
              <el-table-column label="编制人" prop="createUserName"></el-table-column>
              <el-table-column label="操作">
                <template #default="{ row: item }">
                  <el-popconfirm title="确认撤销吗？撤销后需重新审核！" @confirm="doBack(item)" placement="top" v-if="item.status == 1">
                    <template #reference>
                      <el-button type="text" :loading="isLoading" icon="el-icon-refresh">
                        {{ isLoading ? "处理中" : "撤销审核" }}
                      </el-button>
                    </template>
                  </el-popconfirm>
                </template></el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="操作记录">
        <LogHistory :data="logs"></LogHistory>
      </el-tab-pane>
    </el-tabs>
  </div>

  <div class="pageaa-footer">
    <div class="button">
      <el-button icon="el-icon-back" @click="$router.push(_backPath)">返回</el-button>
      <el-button type="success" :loading="isLoading" icon="el-icon-check" @click="doSave(false)">
        {{ isLoading || isFileUploading ? "处理中" : "保存" }}
      </el-button>
      <el-popconfirm title="确认提交吗？提交后不可更改！" @confirm="doSave(true)" placement="top" v-if="form.id != 0">
        <template #reference>
          <el-button type="primary" :loading="isLoading" icon="el-icon-check">
            {{ isLoading || isFileUploading ? "处理中" : "提交" }}
          </el-button>
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script lang="ts">
import LogHistory from "@/views/shared/logHistory.vue";
import fileuploadList from "@/components/fileuploadList";
import roles from "@/types/roles.ts";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
} from "vue";
import { copyFrom } from "@/network/common";
import {
  useSetupProjectAction,
  useQueryFileSignManageActionHistory,
} from "@/network/setupProject";
import {
  dicLabel,
  scheduleTypeDic,
  setupProjectStatusText,
} from "@/network/lookUp.ts";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: {
    fileuploadList,
    LogHistory,
  },
  props: [
    "id",
    "yearBatch",
    "signType",
    "applyUnitId",
    "applyUnitName",
    "redirect",
    "setupGroup"
  ],
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const router = useRouter();
    const route = useRoute();
    const {
      yearBatch = ref(null),
      signType = ref(null),
      applyUnitId = ref(null),
      applyUnitName = ref(null),
      setupGroup = ref(null),
      redirect = ref(null),
    } = toRefs(props);

    const [
      isLoading,
      ,
      ,
      findFileSign,
      saveFileSign,
      queryFileSign,
      findFileSignProjects,
      ,
      ,
      cancelProjectReview,
    ] = useSetupProjectAction();

    const isFileUploading = ref(false);
    const refTable = ref(null);
    const projects = ref([]);
    const selected = ref([]);
    const _backPath = ref(
      redirect.value || route.query.redirect || "/ReportAndReview/ProvSetup"
    );
    const empty = {
      id: 0,
      yearBatch: null,
      signType: null,
      status: 1,
      applyUnitId: null,
      attachList: [],
      extData: {}
    };

    const form = reactive(copyFrom(empty));
    const [logs, queryHistory] = useQueryFileSignManageActionHistory();
    const fileSetting = reactive({
      limit: 1,
      accpet: ".pdf",
      urlKey: "filePath",
      nameKey: "fileName",
      isFileUploading,
      typeGroup: ref({
        list: form.attachList,
        type: "申请文件",
      }),
    });

    const refForm = ref(null);

    onMounted(() => {
      if (props.id) {
        queryHistory(props.id);
        findFileSign(props.id).then((p) => {
          Object.assign(form, p);

          if (form.id == 0) {
            location.href = "/";
            return;
          }
          if (
            (form.applyUnitId != user.id || form.status != 1) &&
            !user.inRoles(roles.admin)
          ) {
            location.href = "/";
            return;
          }
          fileSetting.typeGroup.list = form.attachList;
          doFindFileSignProjects();
        });
      } else {
        if (!yearBatch.value) {
          const createPropsStr = sessionStorage.getItem(
            "setupProjectFileSignMange_CreateProps"
          );
          if (createPropsStr == "" || createPropsStr == null) {
            location.href = "/";
            return;
          } else {
            const createProps = JSON.parse(createPropsStr);
            yearBatch.value = createProps.yearBatch;
            signType.value = createProps.signType;
            applyUnitId.value = createProps.applyUnitId;
            applyUnitName.value = createProps.applyUnitName;
            _backPath.value = createProps.redirect;
            setupGroup.value = createProps.setupGroup;
          }
        }
        form.yearBatch = yearBatch.value;
        form.signType = signType.value;
        form.applyUnitId = applyUnitId.value;
        form.applyUnitName = applyUnitName.value;
        fileSetting.typeGroup.list = form.attachList;
        form.extData.setupGroup = setupGroup.value;
        doFindFileSignProjects();
        // findFileSignProjects(0).then((p) => {
        //   projects.value = p;
        // });
      }
    });

    const doFindFileSignProjects = () => {
      findFileSignProjects(form.id, { setupGroup: setupGroup.value }).then((p) => {
        projects.value = p;
        p.forEach((row) => {
          if (row.signId == form.id) {
            refTable.value.toggleRowSelection(row, true);
          }
        });
      });
    };

    const doBack = (item) => {
      cancelProjectReview(item.id).then(() => {
        doFindFileSignProjects();
      });
    };
    const doSave = (isSubmit = false) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            if (isSubmit) form.status = 2;
            saveFileSign(
              props.id,
              Object.assign({ projectIds: selected.value }, form)
            ).then((p) => {
              resolve("success");
              //emit("change", form);
              if (isSubmit) {
                router.push(_backPath.value);
              } else {
                if (form.id == 0) {
                  router.replace({ params: { id: p.id || 0 } });
                }
                Object.assign(form, p);
                fileSetting.typeGroup.list = form.attachList;
                queryHistory(p.id);
              }
            });
          } else {
            reject("error");
          }
        });
      });
    };
    const handleSelectionChange = (val) => {
      selected.value = val.map((d) => d.id);

      // projects.value.filter(d => !val.map(p => p.id).some(f => f == d.id)).forEach(p => {
      //   p.signId = null;
      // });

      //console.log(projects.value);
      //console.log(val.map(p => p.id));
    };

    return {
      logs,
      _backPath,
      refTable,
      handleSelectionChange,
      dicLabel,
      scheduleTypeDic,
      setupProjectStatusText,
      isFileUploading,
      fileSetting,
      projects,
      user,
      refForm,
      isLoading,
      form,
      doSave,
      doBack,
      rules: {
        // attachList: [
        //   {
        //     required: true,
        //     message: "请上传申报文件！",
        //     validator: (rule, value, callback) => {
        //       if (!value || value.length == 0) {
        //         return callback(Error(rule.message));
        //       }
        //       callback();
        //     },
        //   },
        // ],
        projects: [
          {
            required: true,
            message: "请选择申报项目",
            validator: (rule, value, callback) => {
              if (selected.value.length == 0) {
                return callback(Error(rule.message));
              }
              callback();
            },
          },
        ],
      },
    };
  },
});
</script>

<style scoped></style>
